<template>
  <div class="w-full">
    <div class="flex pt-20">
      <BannerPackage :is-display-search-inputs="false" />
    </div>
    <div class="mx-auto py-3 px-6 lg:px-8" v-if="package">
      <breadcrumb-component :pages="pages"></breadcrumb-component>
    </div>
    <main class="main" v-if="package != null">
      <div class="page-content mt-2">
        <div class="container">
          <div class="product-details-top">
            <div class="row">
              <div class="col-md-4">
                <div class="product-gallery product-gallery-vertical">
                  <div class="row">
                    <figure class="product-main-image p-3">
                      <img
                        class="h-[10rem] w-full"
                        id="product-zoom"
                        :src="package.image"
                        alt="product image"
                      />
                    </figure>
                    <!-- End .product-main-image -->

                    <!-- End .product-image-gallery -->
                  </div>
                  <!-- End .row -->
                </div>
              </div>

              <div class="col-md-8">
                <div class="product-details">
                  <h3 class="product-title">
                    {{ package.name }} |<span class="badge badge-light">
                      {{ package.shop.provider.name }}
                    </span>
                  </h3>
                  <!-- End .product-title -->

                  <div class="product-price mt-2">
                    <span class="badge badge-danger text-red text-size-att"
                      >{{ package.price }} FCFA</span
                    >
                  </div>
                  <!-- End .product-price -->

                  <div class="product-content mt-2">
                    <div class="text-size-att text-grey">Description</div>
                    <span style="padding-left: 10px">{{
                      package.description
                    }}</span>
                  </div>
                  <!-- End .product-content -->

                  <div class="d-flex">
                    <div class="py-0.5 text-base">
                      <span
                        class="border border-success text-green px-1 rounded-md p mr-1 text-wrap"
                      >
                        {{ package.event_type[0].name }}
                      </span>
                    </div>
                    <div class="py-0.5 text-base text-ellipsis">
                      <span
                        class="border border-info text-green px-1 rounded-md p mr-1 text-wrap"
                      >
                        {{ package.service_type.name }}
                      </span>
                    </div>
                  </div>

                  <div class="details-filter-row details-row-size mt-2">
                    <div class="text-size-att text-grey">Caractéristiques</div>
                    <!-- End .product-nav -->
                    <ul style="padding-left: 10px">
                      <li v-for="ff in package.features">-{{ ff.value }}</li>
                    </ul>
                  </div>
                  <!-- End .details-filter-row -->

                  <!-- End .product-details-footer -->
                </div>
                <div class="col-md-12">
                  <div class="mt-5">
                    <button
                      data-target="#exampleModalCenterC1"
                      data-toggle="modal"
                      class="w-full bg-red-800 text-white py-2 rounded-lg hover:bg-red-900"
                      v-if="user != null"
                    >
                      Commander maintenant
                    </button>
                    <button
                      @click="goAuth"
                      class="w-full bg-red-800 text-white py-2 rounded-lg hover:bg-red-900"
                      v-else
                    >
                      Commander maintenant
                    </button>
                  </div>
                  <div class="mt-1">
                    <button
                      @click=""
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                      class="w-full bg-dark text-white py-2 rounded-lg hover:bg-dark-100"
                    >
                      Commander plus tard
                    </button>
                  </div>
                </div>
                <!-- End .product-details -->
              </div>

            </div>

          </div>
        </div>

        <div
          style="margin-top: 100px"
          class="modal fade"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Date de commande
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <label for="start" style="margin-right: 10px"
                  >Choisissez une date:
                  {{ formatDate(bookingPackage.date_reservation) }}</label
                >

                <span style="display: none">
                  <input
                    type="date"
                    id="pick-date"
                    name="trip-start"
                    :min="Date.now()"
                    max=""
                    v-model="bookingPackage.date_reservation"
                  />
                </span>
                <button @click="pickDate">Choisir la date</button>
              </div>
              <div class="modal-footer">
                <!-- <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Annuler
            </button> -->
                <button
                  type="button"
                  class="btn btn-primary"
                  data-dismiss="modal"
                  @click="bookPackage"
                >
                  Confirmer
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="exampleModalCenterC1"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered"
            role="document"
            style="margin-top: 250px"
          >
            <div class="modal-content">
              <contact-form-component
                :provider="package.provider"
                :user="user"
                :is-open-in-modal="true"
                @quote-request-form="sendQuoteRequest"
              ></contact-form-component>
              <!-- <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLongTitle">
                        Modal title
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div> -->
              <!-- <div class="modal-body">
                      
                    </div> -->
              <!-- <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" class="btn btn-primary">
                        Save changes
                      </button>
                    </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- End .page-content -->
    </main>
    <div v-else><spinner /></div>
    <!-- End .main -->
  </div>
  <!-- End .page-wrapper -->
</template>
<script>
import { API_PREFIX, API_VERSION, PROVIDER } from "@/config";
import { mapActions, mapState } from "vuex";
import BannerPackage from "../components/banner-package.vue";
import moment from "moment";
import { QUOTE_REQUEST_TYPE } from "@/views/wedding-provider/utils";

export default {
  data() {
    return {
      message: "Hello Vue.js!",
      package: null,
      bookingPackage: {},
      user: JSON.parse(this.$store.state.user),
      requestTypes: QUOTE_REQUEST_TYPE,
    };
  },
  computed: {
    // ...mapState('packages',{package:'package'})
    ...mapState("packages", { package: "package" }),
    pages() {
      return [
        { path: '/package-des-prestataires-de-services-mariage-en-cote-divoire', label: 'LISTE DES PACKAGES' },
        { path: '#', label: '	➞' },
        { path: '#', label: this.package.name +' par '+ this.package.provider.name },
      ];
    }
  },
  components: {
    BannerPackage,
  },
  async beforeMount() {},
  async mounted() {
    await this.getPackage();

    console.log("Package ", this.package);
  },
  methods: {
    ...mapActions("packages", ["updatePackage"]),
    ...mapActions("providers", ["makeBookingPackage"]),
    ...mapActions("quoteRequests", ["storeQuoteRequest"]),
    formatDate(date) {
      return moment(date).format("DD, MMM YYYY");
    },
    pickDate() {
      document.querySelector("#pick-date").showPicker();
    },
    bookPackage() {
      if (this.$store.getters.isLoggedIn == false) {
        this.$router.push("/connexion");
      } else {
        this.bookingPackage.package_id = this.package.id;
        this.bookingPackage.customer_id = this.user.id;
        let resp = this.makeBookingPackage(this.bookingPackage);
        if (resp.success != false) {
          this.$createToast(
            {
              title: "Succès !",
              description: "Votre commande a été réservée avec succès",
            },
            {
              position: "top-center",
              type: "success",
              timeout: 10000,
              transition: "bounce",
            }
          );

          // this.bookingPackage.isSuccess=true;
          this.$router.push(
            "/reservation-de-package-de-services-mariage-en-cote-divoire"
          );
        } else {
        }
      }
    },
    getPackage() {
      this.isLoading = true;
      let app = this;
      let id = this.$route.params.id;
      this.$http
        .get(
          PROVIDER.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "get-package-wedding-providers/" +
            id
        )
        .then((response) => {
          app.package = response.data.data;
          if (this.package.image != null || this.package.image.length > 0) {
            this.package.image =
              PROVIDER.DEV_URL + "storage" + this.package.image;
          } else {
            this.package.image = "../../assets/images/logo.png";
          }
          app.isLoading = false;
        })
        .catch((error) => {
          console.log("error", error.response.data);
          this.isLoading = false;
        });
    },
    onClick() {
      this.$ua.trackEvent(
        "Provider",
        "Click",
        this.package.shop.provider.name,
        null
      );
      console.log("CONNECTED ? ", this.$store.getters.isLoggedIn);
      if (this.$store.getters.isLoggedIn == false) {
        this.$router.push("/connexion");
      } else {
        this.$router.push(
          "/prestataires-de-mariage-details/" + this.package.shop.id
        );
        this.updatePackage(this.package);
      }
    },
    async sendQuoteRequest(intent) {
      if (this.$store.getters.isLoggedIn == false) {
        this.$router.push("/connexion");
      } else {
        const quoteRequest = {
          ...intent,
          wedding_provider_id: this.package.provider.id,
          shop_id: this.package.shop_id,
          service: this.package.service_type.name,
          service_type_id: this.package.service_type_id,
          package_id: this.package != null ? this.package.id : null,
        };

        console.log(quoteRequest);

        let number = this.package.provider.phone_number_1.replace(
          /[^a-zA-Z0-9]/g,
          ""
        );

        await this.storeQuoteRequest(quoteRequest);
        if (intent.type === this.requestTypes.PHONE) {
          window.open("tel:" + this.package.provider.phone_number_1, "_blank");
        }
        if (intent.type === this.requestTypes.WHATSAPP) {
          window.open(
            "https://api.whatsapp.com/send?phone=" + number,
            "_blank"
          );
        }
        if (intent.type === this.requestTypes.WEBSITE) {
          window.open(this.package.provider.website, "_blank");
        }
      }
    },
    goAuth() {
      this.$router.push("/connexion");
    },
  },
};
</script>
<style scoped>
.text-size-att {
  font-size: 1.4rem;
}
</style>
