<template>
  <div class="container no-print" style="position: relative; z-index: 1;">
    
    <spinner v-if="loading" />
    <div v-else class="row pb-4 justify-content-center" style="min-height: 300px; background-color: #fff">
      <div>
        <div
          class="d-flex align-items-center"
          title="Newletter"
          style="
            margin-top: 100px;
            position: relative;
            z-index: 900;
            height: 20px;
            margin-left: 50px;
            margin-right: 50px;
            background-image: url('/images/old_img/reservation2.jpg');
            background-size: 100% 100%;
          "
        >
          <div class="d-flex flex-column justify-content-center">
            <!-- <div style="margin-right: 1px; color: black;text-align: center;"> -->
              <h3 style="font-weight: bold; margin: 0px;color: #b71c1c;text-align:center">
                Abonnez vous à notre Newsletter
              </h3>
              <!-- Et restez au fait de l'actualité Jours de Joie -->
            <!-- </div> -->

            <div class="d-flex flex-column  w-100">
              <div style="margin-right: 1px;height:auto;width:100%">
                <label>Email</label>
                <input class="" style="border:1px solid black; border-radius: 3px;height:200%;padding:5px;width:100%" type="text" placeholder="" v-model="newsletter_email"/>
              </div>
              <div class="d-flex flex-row justify-content-center" style="padding: 10px; ">
                <input type="checkbox" id="scales" name="scales" class="mx-1" style="padding:10px;width:20px"  />
                <label for="scales" style="font-size: smaller;">J'accepte de reçevoir des e-mails et/ou des SMS, étant entendu que je peux facilement me désinscrire de ces communications à tout moment après mon inscription.</label>
              </div>

            </div>
            <div class="align-self-center" style="margin-top: 10px">
                <button
                @click="newsletter('M')"
                  type=""
                  class="" style="border-radius: 3px;background-color: #b71c1c;padding:10px;color:white;"
                >
                  Je m'abonne
                </button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.newsletter input {
  box-shadow: none !important;
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ebebeb;
  border-radius: 1px;
  box-sizing: border-box;
  font-size: 14px;
  height: 40px;
  line-height: 1.42857;
  outline: medium none;
  padding: 0 9px;
  width: 100%;
}
.btn-submit {
  background: #b71c1c none repeat scroll 0 0;
  border: 0 none;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  padding: 0 25px;
  text-transform: uppercase;
}
</style>
<script>
import { API_PREFIX, API_VERSION, CONTACT } from "@/config";
import axios from "axios";

export default {
  name: "newsletter",
  data() {
    return {
      newsletter_email: "",
      loading: false,
    };
  },

  methods: {
    newsletter(value) {
      let app = this;
      // this.$validator.validateAll("newsletter-form").then(valid => {
      //   if (valid) {
      // app.$Progress.start();
      app.loading = true;
      axios
        .post(CONTACT.DEV_URL + API_PREFIX + API_VERSION + "newsletter", {
          email: app.newsletter_email,
          sexe: value,
        })
        .then(function (response) {
          app.loading = false;
          app.$createToast(
                {
                  title: 'Abonnement réussi',
                  description: "Vous êtes maintenant abonné à notre Newsletter.",
                },
                {
                  position: 'top-center',
                  type: 'success',
                  timeout: 10000,
                  transition: 'bounce',
                })
        })
        .catch(function (error) {
          console.log(error);
        });
      //   }
      // });
    },
  },
};
</script>
