<template>
  <div class="container">
    <div class="row row-cols-3" v-if="packages != null">
      <div class="col-md-4" v-for="pp in packages">
        <CardPackageItem :pack="pp" />
      </div>
    </div>
    <div class="row row-cols-3" v-if="packages.length == 0">
      <div
        class="d-flex flex-column justify-content-center align-items-center"
        style="margin: auto"
      >
        <div>
          <img src="../../assets/images/rb_1024.png" style="height: 100px" />
        </div>
        <div>Aucun package associé à cette entrée...</div>
      </div>
    </div>
  </div>
</template>
<style></style>
<script>
import CardPackageItem from "./card-package-item.vue";
export default {
  props: {
    packages: Array,
  },
  data: () => {
    return {};
  },
  mounted() {
    console.log("mounted ", this.packages);
  },
  components: {
    CardPackageItem,
  },
};
</script>
