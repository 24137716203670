<template>
  <!-- <div class="d-flex justify-content-center container mt-5" v-if="pack!=undefined">
      <div class="card p-3 bg-white card-item">
        <div class="about-product text-center mt-2">
          <img :src="pack.image"  style="width:500px;height:200px;object-fit: cover;" class="rounded" />
          <div>
            <h4>{{ pack.name }}</h4>
            <h6 class="mt-0 text-black-50">{{ pack.shop.provider.name }}</h6>
          </div>
        </div>
        <div class="stats mt-2">
          <div class="d-flex justify-content-center p-price">
            <div class="button-60">{{ pack.event_type[0].name }}</div>
          </div>
          <div class="d-flex justify-content-center p-price mt-1">
            <div class="button-60" >{{ pack.service_type.name }}</div>
          </div>
        </div>
        <div class="d-flex justify-content-between total font-weight-bold mt-4">
          <span>Total</span><span>{{ pack.price }} FCFA</span>
        </div>
        <div>
          <div class="d-flex justify-content-end">
            <button @click="addToCart(pack)" class="col-12 btn btn-primary">Voir</button>
          </div>
        </div>
      </div>
    </div> -->
  <div
    class="relative bg-white shadow-lg rounded-lg h-fit mx-2 mt-5"
    v-if="pack != null"
  >
    <div class="shadow-lg">
      <img
        :class="{
          'rounded-t-lg mx-auto h-[15rem]': true,
          'w-full rounded-tl-lg rounded-bl-lg': true,
        }"
        class="h-full w-full object-cover"
        :src="pack.image"
      />
    </div>
    <div class="py-2 px-2" :class="{ 'flex flex-col w-2/3': isList }">
      <div class="pt-1 pb-0.5">
        <div class="font-semibold text-ellipsis text-capitalize text-center">
          {{ pack.name }}
          <div v-if="pack.shop.provider != undefined">
            <span
              class="border border-green text-green px-3 rounded-md py-1 mr-4"
            >
              <i class="fa fa-shopping-bag text-warning"></i>
              {{ pack.shop.provider.name }}
            </span>
          </div>
        </div>
      </div>
      <div class="py-0.5 text-xs text-center">
        <span
          class="border border-green text-green px-1 rounded-md p mr-1 text-wrap"
        >
          {{ pack.event_type[0].name }}
        </span>
      </div>
      <div class="py-0.5 text-xs text-ellipsis text-center">
        {{ pack.service_type.name }}
      </div>
      <div class="d-flex justify-content-between total font-weight-bold mt-1">
        <span>Prix</span><span>{{ pack.price }} FCFA</span>
      </div>
      <div class="py-0.5">
        <button
          @click="goDetail()"
          class="w-full bg-red-800 text-white py-2 rounded-lg hover:bg-red-900"
        >
          Voir
        </button>
      </div>

      <div class="py-0.5">
        <button
          @click=""
          class="w-full bg-red-800 text-white py-2 rounded-lg hover:bg-red-900"
          data-target="#exampleModalCenterC1"
          data-toggle="modal" v-if="user!=null"
        >
          Commander maintenant
        </button>
        <button
          @click="goAuth"
          class="w-full bg-red-800 text-white py-2 rounded-lg hover:bg-red-900"
          v-else
        >
          Commander maintenant
        </button>
      </div>

      <div class="py-0.5">
        <button
          @click=""
          type="button"
          data-toggle="modal"
          data-target="#exampleModalCenter"
          class="w-full bg-dark text-white py-2 rounded-lg hover:bg-dark-100"
        >
          Commander plus tard
        </button>
      </div>
    </div>
    <div
      style="margin-top: 100px"
      class="modal fade"
      id="exampleModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Date de commande
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label for="start" style="margin-right: 10px"
              >Choisissez une date:
              {{ formatDate(bookingPackage.date_reservation) }}</label
            >

            <span style="display: none">
              <input
                type="date"
                id="pick-date"
                name="trip-start"
                :min="Date.now()"
                max=""
                v-model="bookingPackage.date_reservation"
              />
            </span>
            <button @click="pickDate">Choisir la date</button>
          </div>
          <div class="modal-footer">
            <!-- <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Annuler
            </button> -->
            <button 
            data-dismiss="modal"
            type="button" class="btn btn-primary" @click="bookPackage">
              Confirmer
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal d'envoie de demande de devis -->
    <div
      class="modal fade"
      id="exampleModalCenterC1"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="margin-top: 250px"
      >
        <div class="modal-content">
          <contact-form-component
            :provider="pack.provider"
            :user="user"
            :is-open-in-modal="true"
            @quote-request-form="sendQuoteRequest"
          ></contact-form-component>
          <!-- <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLongTitle">
                        Modal title
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div> -->
          <!-- <div class="modal-body">
                      
                    </div> -->
          <!-- <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" class="btn btn-primary">
                        Save changes
                      </button>
                    </div> -->
        </div>
      </div>
    </div>
  </div>

  <spinner v-else></spinner>
</template>
<style>
.card-item {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

/* CSS */
.button-60 {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 0.375em;
  box-shadow: none;
  box-sizing: border-box;
  color: #363636;
  cursor: pointer;
  display: inline-flex;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: 1rem;
  height: 2.5em;
  justify-content: center;
  line-height: 1.5;
  padding: calc(0.5em - 1px) 1em;
  position: relative;
  text-align: center;
  user-select: none;
  /* -webkit-user-select: none; */
  /* touch-action: manipulation; */
  vertical-align: top;
  white-space: nowrap;
}

.button-60:active {
  border-color: #4a4a4a;
  outline: 0;
}

.button-60:focus {
  border-color: #485fc7;
  outline: 0;
}

.button-60:hover {
  border-color: #b5b5b5;
}

.button-60:focus:not(:active) {
  box-shadow: rgba(72, 95, 199, 0.25) 0 0 0 0.125em;
}
</style>
<script>
import { PROVIDER } from "@/config";
import { mapActions, mapState } from "vuex";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import { QUOTE_REQUEST_TYPE } from "@/views/wedding-provider/utils";
import store from "@/store";

export default {
  props: ["pack"],
  data() {
    return {
      bookingPackage: {},
      user: JSON.parse(store.state.user),
      requestTypes: QUOTE_REQUEST_TYPE,
    };
  },
  computed: {
    ...mapState("packages", { package: "package" }),
  },
  mounted() {
    console.log("CardItemPackage ", this.pack);
    if (this.pack.image != null || this.pack.image.length > 0) {
      this.pack.image = PROVIDER.DEV_URL + "storage" + this.pack.image;
    } else {
      this.pack.image = "../../assets/images/logo.png";
    }
  },
  methods: {
    ...mapActions("packages", ["updatePackage"]),
    ...mapActions("providers", ["makeBookingPackage"]),
    ...mapActions("quoteRequests", ["storeQuoteRequest"]),

    onClick(providerId) {
      this.$ua.trackEvent(
        "Provider",
        "Click",
        this.pack.shop.provider.name,
        null
      );
      console.log("CONNECTED ? ", this.$store.getters.isLoggedIn);
      if (this.$store.getters.isLoggedIn == false) {
        this.$router.push("/connexion");
      } else {
        this.$router.push("/prestataires-de-mariage-details/" + providerId);
        this.updatePackage(this.pack);
      }
    },
    goDetail() {
      this.$router.push(
        "/description-package-des-prestataires-de-services-en-cote-divoire/" +
          this.pack.id
      );
    },
    formatDate(date) {
      return moment(date).format("DD, MMM YYYY");
    },
    pickDate() {
      document.querySelector("#pick-date").showPicker();
    },
    bookPackage() {
      if (this.$store.getters.isLoggedIn == false) {
        this.$router.push("/connexion");
      } else {
        this.bookingPackage.package_id = this.pack.id;
        this.bookingPackage.customer_id = this.user.id;
        let resp = this.makeBookingPackage(this.bookingPackage);
        if (resp.success != false) {
          this.$createToast(
            {
              title: "Succès !",
              description: "Votre commande a été réservée avec succès",
            },
            {
              position: "top-center",
              type: "success",
              timeout: 10000,
              transition: "bounce",
            }
          );

          // this.bookingPackage.isSuccess=true;
          this.$router.push(
            "/reservation-de-package-de-services-mariage-en-cote-divoire"
          );
        } else {
        }
      }
    },
    async sendQuoteRequest(intent) {
      if (this.$store.getters.isLoggedIn == false) {
        this.$router.push("/connexion");
      } else {
        const quoteRequest = {
          ...intent,
          wedding_provider_id: this.pack.provider.id,
          shop_id: this.pack.shop_id,
          service: this.pack.service_type.name,
          service_type_id: this.pack.service_type_id,
          package_id: this.pack != null ? this.pack.id : null,
        };

        console.log(quoteRequest);

        let number = this.pack.provider.phone_number_1.replace(
          /[^a-zA-Z0-9]/g,
          ""
        );

        await this.storeQuoteRequest(quoteRequest);
        if (intent.type === this.requestTypes.PHONE) {
          window.open("tel:" + this.pack.provider.phone_number_1, "_blank");
        }
        if (intent.type === this.requestTypes.WHATSAPP) {
          window.open(
            "https://api.whatsapp.com/send?phone=" + number,
            "_blank"
          );
        }
        if (intent.type === this.requestTypes.WEBSITE) {
          window.open(this.pack.provider.website, "_blank");
        }
      }
    },
    goAuth(){
      this.$router.push("/connexion");
    }
  },
};
</script>
