<template>

  <div class="fixed top-0 left-0 w-screen h-screen z-20 overflow-block" >
    <div class="bg-gray-200 h-full">
      <div class="flex flex-row bg-white py-2">
        <div class="flex flex-row flex-1 col-md-4">
          <div class="px-2 h-fit w-fit flex items-center text-gray-500 text-xs">
            <button class="flex items-center border rounded-full px-4 py-2" @click="share">
              <!-- <font-awesome-layers>
                <font-awesome-icon class="text-gray-500" icon="fa-share-nodes"></font-awesome-icon>
              </font-awesome-layers>&nbsp; -->
              <!-- <i class="text-gray-500 fa fa-share-nodes"></i> -->
              <i class="fa fa-facebook border"></i> &nbsp;
              <span>
                Partager
              </span>
            </button>&nbsp;
          </div>
        </div>
        <div class="flex flex-row flex-1 items-center justify-items-center col-md-4">
          <div class="w-full text-center text-gray-700 text-nowrap text-xs">
            {{ provider.name }}
            
          </div>
        </div>
        <div class="flex flex-row flex-1 items-center justify-end w-full col-md-4">
          <div class="flex px-2 border-r text-gray-500">
            <!-- <font-awesome-layers>
              <font-awesome-icon icon="fa-th-large"></font-awesome-icon>
            </font-awesome-layers> -->
            <i class="fa fa-th-large"></i>
          </div>
          <div class="flex px-2 border-r text-gray-500" v-if="medias">
            {{ `${index + 1}/${medias.length}` }}
          </div>
          <button class="py-2 px-2 md:mr-8 md:ml-4 border-gray-500 text-gray-500" @click.prevent="$emit('display-portfolio-modal', false)">
            <!-- <font-awesome-layers>
              <font-awesome-icon icon="fa-close"></font-awesome-icon>
            </font-awesome-layers> -->
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>

      <div class="flex flex-col md:flex-row mx-auto my-2 md:my-8 mx-8">
        <div class="w-full lg:flex ld:flex-row ld:w-2/3">
          <div class="flex md:flex-1 bg-white w-full max-h-full items-center mx-2 lg:mr-2 lg:ml-0">
            <button class="px-4 py-2 border h-fit w-fit rounded-full" @click="prev">
              <i class="text-gray-600 fa fa-angle-left"></i>
              <!-- <font-awesome-icon class="text-gray-600" icon="fa-angle-left"></font-awesome-icon> -->
            </button>
            <div class="w-full h-full text-center md:h-[45rem] fade-in-image" v-if="currentMedia && currentMedia.type && currentMedia.type === 'photo'">
              
              <img
                  class="mx-auto w-auto h-full object-cover "
                  :src="url({ path: currentMedia.path, type: currentMedia.type })"
              />
            </div>
            <div class="w-full h-full md:h-[45rem]" v-if="currentMedia && currentMedia.type && currentMedia.type === 'youtube_video'">
              <iframe :src="url({path: currentMedia.path, type: 'youtube_video'})" class="mx-auto w-full h-full" width="auto" height="520" allowfullscreen></iframe>
            </div>
            <div class="w-full h-full md:h-[45rem]" v-if="currentMedia && currentMedia.type && currentMedia.type === 'tiktok_video'">
              <!-- {{ url({ path: currentMedia.path, type: currentMedia.type }) }} -->
              <!-- {{datares.data.html  }} -->
              <iframe :src="'https://www.tiktok.com/embed/v2/'+currentMedia.path" frameborder="0" width="100%" height="80%" allowfullscreen></iframe>
              <!-- <blockquote class="tiktok-embed" cite="https://www.tiktok.com/@cedric_anani/video/7276028507245776133" data-video-id="7276028507245776133" data-embed-from="oembed" style="max-width: 605px;min-width: 325px;" > <section> <a target="_blank" title="@cedric_anani" href="https://www.tiktok.com/@cedric_anani?refer=embed">@cedric_anani</a> <p>sois l'intercesseur d'une personne ou dun système  <a title="jesus" target="_blank" href="https://www.tiktok.com/tag/jesus?refer=embed">#jesus</a> <a title="niger" target="_blank" href="https://www.tiktok.com/tag/niger?refer=embed">#niger</a> <a title="abidjan" target="_blank" href="https://www.tiktok.com/tag/abidjan?refer=embed">#abidjan</a> <a title="cedricanani" target="_blank" href="https://www.tiktok.com/tag/cedricanani?refer=embed">#cedricanani</a> </p> <a target="_blank" title="♬ son original - Cedric Kouadio Anani" href="https://www.tiktok.com/music/son-original-7276028541643737862?refer=embed">♬ son original - Cedric Kouadio Anani</a> </section> </blockquote> -->
            </div>
            <div class="w-full" v-if="!currentMedia || !currentMedia.type">
              Chargement en cours ...
            </div>
            <button class="px-4 py-2 border h-fit w-fit rounded-full" @click="next">
              <i class="text-gray-600 fa fa-angle-right"></i>
              <!-- <font-awesome-icon class="text-gray-600" icon="fa-angle-right"></font-awesome-icon> -->
            </button>
          </div>
        </div>
        <div class="hidden bg-white items-center justify-center lg:flex lg:flex-row lg:w-1/3 lg:ml-2 px-8 py-2" v-if="provider">
          <contact-form-component :provider="provider" @quote-request-form="sendQuoteRequest"></contact-form-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGoodPath,requests,requestHeaders } from '@/views/wedding-provider/utils'
import axios from 'axios';

export default {
  name: "portfolio-modal-component",
  props: ['medias', 'provider','currentType','videos','photos'],
  emits: ['display-portfolio-modal'],
  data: () => ({
    currentMedia: null,
    index: 0,
    datares : null,
    
  }),
  watch: {
    medias: {
      handler: function (val) {
        // setTimeout(() => {
          if (this.currentType) {
            
          if (this.currentType == 'photo') {
            this.currentMedia = this.photos[0]
          } else {
            this.currentMedia = this.videos[0]
          }
          
        } else {
          
          this.currentMedia = val[0]
        }
        // }, 500);
        
        
      },
      immediate: true
    }
  },
  
  methods: {
    url(media){
      let test = null
      if (media.type == "tiktok_video") {
         
        }else{
          test = getGoodPath(media)
        }
      return test;
    } ,
    next() {
      
      if (this.currentType) {
        if (this.currentType == 'photo') {
          this.index = this.photos.length <= this.index + 1 ? 0 : this.index + 1;
          this.currentMedia = this.photos[this.index];
        }else{
          this.index = this.videos.length <= this.index + 1 ? 0 : this.index + 1;
          this.currentMedia = this.videos[this.index];
        }
      }else {
        this.index = this.medias.length <= this.index + 1 ? 0 : this.index + 1;
        this.currentMedia = this.medias[this.index];
      }
        
    },
    prev() {
      
      if (this.currentType) {
        if (this.currentType == 'photo') {
          this.index = this.index > 0 ? this.index - 1 : this.photos.length-1;
          this.currentMedia = this.photos[this.index];
        }else{
          this.index = this.index > 0 ? this.index - 1 : this.videos.length-1;
          this.currentMedia = this.videos[this.index];
        }
      }else{
        this.index = this.index > 0 ? this.index - 1 : this.medias.length-1;
        this.currentMedia = this.medias[this.index];
      }
    },
    sendQuoteRequest(data) {
      this.$emit('quote-request-form-modal', data);
    },
    share() {
      window.open(
              "https://www.facebook.com/sharer/sharer.php?u="+window.location.href,
              "_blank"
            );
    }
  }
}
</script>

<style scoped>
  .fade-in-image { animation: fadeIn 5s; }
</style>